import { createApp } from "/node_modules/.vite/deps/vue.js?v=25faa54c";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=2a1b56d3";

import App from "/src/App.vue";
import router from "/src/router/index.js";

import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');
