import { createApp } from "/node_modules/.vite/deps/vue.js?v=e9f442b3";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=fa2fa857";

import App from "/src/App.vue";
import router from "/src/router/index.js";

import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');
